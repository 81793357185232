import { getSiteInfo } from '@/utils/api'
export const useSiteStore = defineStore('app', {
  state: () => ({
    nanoid: 1000,
    floatIconColor: '#05b586',
    // 网站信息
    site: {
      copyright: '',
      logo_sl: '',
      beian: '',
      ym_tit: '飞天信息', //页面标题---SEO用
      ym_key: '飞天信息', //页面关键字---SEO用
      ym_des: '飞天信息' //页面描述---SEO用
    },
    // 图标
    iconList: [{ title: '哔哩哔哩', link_url: 'https://www.bilibili.com/', img_sl: 'http://all.biubug.cn/storage/upimg/20240206/202402061428221054.png' }],
    kefu: {
      title: '第三方客服链接代码',
      link_url: 'http://flysky.cn/admin/index/index.html', //客服链接
      js_code: ''
    },
    right: {
      phone: '+（86）88444355', //电话
      wx_title: '扫一扫关注', //二维码标题
      img_sl: 'http://all.biubug.cn/storage/upimg/20240206/202402061458099036.png' //二维码
    }
  }),
  getters: {
    getNanoid: (state) => state.nanoid
  },
  actions: {
    async setSiteInfo() {
      const { icon_list, site, kefu, right }: any = await getSiteInfo()
      this.site = site
      this.iconList = icon_list
      this.kefu = kefu
      this.right = right
      return site
    },
    setFloatIconColor(color) {
      this.floatIconColor = color
    }
  },
  persist: [
    {
      paths: ['nanoid'],
      storage: persistedState.cookiesWithOptions({ maxAge: 604800 })
    }
  ]
})
